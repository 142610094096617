const Footer = () => {
    return (<><div className="text-white mx-auto text-center my-8 py-12 font-medium text-base">
      <div className="mb-2 font-recoleta font-light text-2xl">"We aren't for everyone."</div>
      <div className="uppercase"><a href="https://nplsk.info">&#10084;&nbsp;&nbsp;The Napolski's</a>&nbsp;&nbsp;&bull;&nbsp;&nbsp;2024</div>      
    </div>
    <div className="mt-64 max-w-2xl mx-auto pb-32 text-white px-4">
      <h6 className="font-bg font-bold text-center mb-4 text-lg tracking-widest">WARDEN'S NOTICE</h6>
      <h5 className="font-recoleta font-black text-center text-4xl md:text-6xl">These tunes not intended to be played anywhere below 100% full volume.</h5>
      <p className="text-center mb-12 text-xl mt-4">Who cares what the neighbors say?</p>
      <p className="text-center font-bold mb-8"><a className="border-2 rounded px-6 py-2" rel="noreferrer" href="https://open.spotify.com/playlist/2U4zJpYAyB9xVokLjefQsx?si=39b3309fcddd4175" target="_blank">Open in Spotify</a></p>
    <iframe title="Nick's Private Stash" className="rounded-2xl" src="https://open.spotify.com/embed/playlist/2U4zJpYAyB9xVokLjefQsx?utm_source=generator&theme=0" width="100%" height="680" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
    </div>
    </>)
  };
  
  export default Footer;