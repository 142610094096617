
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import SecretPage from './components/SecretPage';
import './App.css';
import './index.css';


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/secret" element={<SecretPage />} />
      </Routes>
    </Router>
  );
};

export default App;
