import Flickity from 'react-flickity-component';
import "flickity/css/flickity.css";

const Carousel = () => {
  const flickityOptions = {
    initialIndex: 2,
    wrapAround: true,
    lazyLoad: 3,
    pageDots: false,
    imagesLoaded: true,
  };

  // Dynamically generate image elements
  const generateImages = () => {
    let images = [];
    for (let i = 0; i <= 117; i++) {
      const imageName = `nicholas-napolski-${String(i).padStart(3, '0')}.jpg`;
      const imagePath = `/img/slideshow/${imageName}`;
      images.push(
        <div key={i} className="carousel-cell h-[240px] il:h-[340px] sm:h-[480px] md:h-[600px] p-1 sm:p-4 lg:p-0 w-auto"> 
          <img src={imagePath} alt={`Nicholas Napolski ${i + 1}`} className="rounded-lg h-full w-auto object-cover" />
        </div>

      );
    }
    return images;
  };

  return (
    <>
    <div id="about" className="mt-16"></div>    
    <Flickity      
      elementType={'div'} // default 'div'
      options={flickityOptions} // takes flickity options {}
      disableImagesLoaded={false} // default false
      className={'carousel my-8 py-8 md:py-24'}
      reloadOnUpdate // default false
      static // default false
    >
      {generateImages()}
    </Flickity>
    </>
  );
};

export default Carousel;
