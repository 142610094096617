import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCsmBGfY_SIUEljWsJ81QpgDafcgPsWoX8",
  authDomain: "nick-napolski-memorial.firebaseapp.com",
  projectId: "nick-napolski-memorial",
  storageBucket: "nick-napolski-memorial.appspot.com",
  messagingSenderId: "994269204055",
  appId: "1:994269204055:web:12db5497a7f7e38ff39d12",
  measurementId: "G-2RV6MSMG72"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);