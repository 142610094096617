// SecretPage.js

import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase'; // Adjust the path as needed

const SecretPage = () => {
    const [rsvps, setRsvps] = useState([]);

    const fetchRsvps = async () => {
      try {
          const querySnapshot = await getDocs(collection(firestore, "rsvps"));
          const unsortedData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
          // Sort RSVPs by name alphabetically, case-insensitive
          const sortedData = unsortedData.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
          setRsvps(sortedData);
          
      } catch (error) {
          console.error("Error fetching RSVPs:", error);
      }
    };
  

    useEffect(() => {
        fetchRsvps();
    }, []);

    const copyEmailsToClipboard = () => {
      // Filter the RSVPs to include only those who have agreed to share a story
      const emailsOfStorySharers = rsvps
        .filter(rsvp => rsvp.shareStory && rsvp.email) // Ensure rsvp.email exists to avoid undefined values
        .map(rsvp => rsvp.email)
        .join(', '); // Joins emails with a comma and a space

      navigator.clipboard.writeText(emailsOfStorySharers)
        .then(() => {
          alert('Emails of story sharers copied to clipboard!'); // Feedback to the user
        })
        .catch(err => {
          console.error('Failed to copy emails: ', err);
        });
    };
    
    const totalNumPeople = rsvps.reduce((total, rsvp) => total + Number(rsvp.numPeople), 0);


  return (
    <div className="container mx-auto py-6">
      <div className="flex justify-between mb-5">
        <div className='flex items-center'>
          <h1 className="text-3xl text-white font-extrabold">RSVP</h1>
          <div className="ml-2 px-2 text-2xl font-bold bg-green-100 text-green-700 border border-green-500 rounded-full inline-block">{totalNumPeople}</div>
        </div>
        <div>
          <button className="px-4 py-2 font-bold border-2 border-white rounded-lg text-white" onClick={copyEmailsToClipboard}>
            Copy Story Sharers' Emails
          </button>
        </div>
      </div>
            
      <div className='not-prose relative rounded-xl bg-slate-100 overflow-x-auto mb-8'>
        <table className='min-w-full'>
          <thead className='text-left'>
            <tr>
              <th className='border-b font-medium text-slate-500 cell'>Name</th>
              <th className='border-b font-medium text-slate-500 cell'>Guests</th>
              <th className='border-b font-medium text-slate-500 cell'>Share a Story</th>
              <th className='border-b font-medium text-slate-500 cell'>Email</th>              
              <th className='border-b font-medium text-slate-500 cell'>Response</th>
              <th className='border-b font-medium text-slate-500 cell'>Date</th>
            </tr>
          </thead>
          <tbody>
            {rsvps.map((rsvp) => (
              <tr key={rsvp.id} className='row'>
                <td className='font-semibold cell'>{rsvp.name}</td>
                <td className='cell'>{rsvp.numPeople}</td> 
                <td className='cell'>{rsvp.shareStory ? "Yes" : "No"}</td>
                <td className='cell'>{rsvp.email}</td>                
                <td className='cell'>{rsvp.response}</td>              
                <td className='cell'>{rsvp.timestamp.toDate().toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>

  );
};

export default SecretPage;
