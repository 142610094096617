const Bio = () => {
    return(
      <div className="bio text-white md:text-[#DEE3FF] text-pretty font-normal font-recoleta leading-loose sm:leading-loose md:leading-loose mx-auto px-8 sm:px-16 max-w-5xl text-xl sm:text-2xl md:text-3xl mb-24 mt-8 sm:tracking-[0.015em]" style={{maxInlineSize:1024}}>


       
        
        
        <p>Nicholas Edward Napolski, a warm and radiant soul, was lovingly welcomed back into the arms of his ancestors on February 11, 2024. Born under the spring sky of Toledo, OH, on May 3, 1954, Nick's life was a testament to the power of humor, kindness, and an irresistible charisma that touched the hearts of not just his family and friends, but also every person fortunate enough to cross his path. His remarkable ability to forge friendships across diverse walks of life left an enduring mark on all who knew him.</p> 

        <figure className="sm:float-right mb-8 lg:mb-4 sm:mt-4 object-cover sm:ml-16 sm:w-1/2 xl:w-1/2 xl:-mr-36">
          <img src="/img/nicholas-napolski-obit-001.jpg" alt="Baby Nick and Grandpa Ed Napolski" className="mb-4 rounded-lg" />
          <figcaption className="text-sm text-center md:text-right font-bg font-medium tracking-normal text-white/75">Baby Nick and Grandpa Ed Napolski &ndash; Toledo, 1954</figcaption>
        </figure>

        <p>Nick believed that the greatest joys are found in the simplicity of living a life true to one's values, surrounded by the people and activities that bring us closest to our essence. His legacy is a vivid expression of love, resilience, and bold strength, embodied in unwavering acts of kindness and integrity.</p>

        <p>Nick embraced the rugged and indispensable role of a union dockworker at Yellow Freight, enduring 25 years of Buffalo's notorious winters. While still working at Yellow, 
        he joined the Snyder Volunteer Fire Department, demonstrating his commitment to helping his community at a moment's notice.         
        Upon retiring, he took on the role of a Blood Product Courier for UNYTS, where he enjoyed zipping around town, delivering life-saving products, and making connections with a wide array of people, spreading his distinctive warmth and friendliness wherever he went.</p>        
        
        <figure className="mb-8 object-cover w-full">
          <img src="/img/nicholas-napolski-obit-008.jpg" alt="The Family" className="mb-4 rounded-lg" />
          <figcaption className="text-sm text-center md:text-right font-bg font-medium tracking-normal text-white/75">Nick at the firehouse&ndash; Buffalo, 2006</figcaption>
        </figure>

        <p>Nick's soul found joy in the kitchen, where he transformed simple ingredients into expressions of love, what he knowingly called “peasant food”&mdash;each dish underscoring his drive to nurture and share. Beyond the kitchen, his passion extended to music, a profound emotional connection that colored every aspect of his life, offering solace, joy, and a deeper resonance with those around&nbsp;him.</p>
           
        <figure className="md:float-left mb-8 md:mb-4 mt-8 object-cover md:mr-12 lg:mr-16 md:w-1/2 xl:w-2/3 xl:-ml-36">
          <img src="/img/nicholas-napolski-obit-005.jpg" alt="Nick in the firehall kitchen at Nicole's graduation party" className="mb-4 rounded-lg" />
          <figcaption className="text-sm text-center md:text-right font-bg font-medium tracking-normal text-white/75">Nick in the firehall kitchen at Nicole's graduation &ndash; Buffalo, 2004</figcaption>
         </figure> 
        
         <p>His outdoor pursuits, especially cycling with his wife along Rails to Trails pathways, were more than physical activity—they were moments of reflection, a deeper bond with American history and nature. They towed their RV, the NoBo (No Boundaries / No Bullshit), embarking on adventures across the open road, chasing a dream of limitless exploration. This period, though fleeting, was rich with the spirit of freedom and shared discovery, embodying their adventurous hearts and love for the untamed.</p>
   
        <p>Nick's story began alongside Sharon Napolski (nee DuBois), the girl next door who captured his heart in their shared hometown of Toledo, OH. Their friendship blossomed into a love that was sealed in marriage in 1979. In 1989, they laid down roots in Buffalo, NY, where they nurtured a family that overflowed with love, laughter, and cherished memories. </p>

        <figure className="mb-16 object-cover w-full">
          <img src="/img/nicholas-napolski-obit-003.jpg" alt="Nick and Sha in the shade" className="mb-4 rounded-lg" />
          <figcaption className="text-sm text-center md:text-right font-bg font-medium tracking-normal text-white/75">Nick &amp; Sha in the shade &ndash; Arizona, 1980?</figcaption>
        </figure>
                
        <figure className="mb-16 object-cover w-full">
          <img src="/img/nicholas-napolski-obit-002.jpg" alt="Nick and Sha on their Wedding Day" className="mb-4 rounded-lg" />
          <figcaption className="text-sm text-center md:text-right font-bg font-medium tracking-normal text-white/75">Nick &amp; Sha's Wedding Day &ndash; Toledo, 1979</figcaption>
        </figure> 
        
        <figure className="mb-16 object-cover w-full">
          <img src="/img/nicholas-napolski-obit-007.jpg" alt="Nick & Sha at Great Allegheny Passage, Pennsylvania, Sept. 2023" className="mb-4 rounded-lg" />
          <figcaption className="text-sm text-center font-bg font-medium tracking-normal text-white/75">Nick &amp; Sha at Great Allegheny Passage, Pennsylvania, Sept. 2023</figcaption>
        </figure>


   
        <p>He leaves behind a legacy of love with his wife, Sharon; his son, Frank Napolski (Emily Maracle); his daughter, Nicole Napolski (Matthew Herrington); his beloved grandson, Weston Herrington; his brother, Daniel Napolski; and a tapestry of family members who will continue to honor his memory. Nick was lovingly ushered into eternity by his father, Bernard Napolski; his mother, Bernice Napolski (Germano); and his brothers, Tim and Edward Napolski.</p>
        <figure className="sm:float-right mb-8 md:mb-0 object-cover sm:ml-8 sm:w-1/2 xl:w-2/3 xl:-mr-36">
          <img src="/img/nicholas-napolski-obit-004.jpg" alt="The Family" className="mb-4 rounded-lg" />
          <figcaption className="text-sm text-center md:text-right font-bg font-medium tracking-normal text-white/75">Peanut, Sha, Nick, and Franco &ndash; Toledo, 1987</figcaption>
        </figure>
       
        <p>In recognition of a life filled with meaningful connections and unexpected joys, we invite all whose hearts were touched by Nick to gather in celebration of his love at the Niawanda Park Pavilion in Tonawanda, NY. Though his journey concluded sooner than we hoped, his spirit and essence remain a guiding light and a precious gift to us all. Let us unite to honor the unforgettable path he walked among us, a path marked by laughter, kindness, and an unwavering dedication to the good&nbsp;in&nbsp;life.</p>
                
        <figure className="mb-8 mt-4 object-cover sm:mr-16 w-full xl:-mr-36">
          <img src="/img/nicholas-napolski-obit-006.jpg" alt="Daddie NP" className="mb-4 rounded-lg" />
          <figcaption className="text-sm text-center font-bg font-medium tracking-normal text-white/75">Down by the River (photo by Frankie) &ndash; Somewhere outside DC, 2002</figcaption>
        </figure>
      </div>
    );
  };
  
  export default Bio;