import React, {useState, useEffect, useRef} from "react";
// import { Link } from 'react-router-dom';
import Footer from "./Footer";
import Bio from "./Bio";
import MemoriesAndCondolences from "./MemoriesAndCondolences";
import RSVPForm from "./RSVPForm";
import Carousel from "./Carousel";

const HomePage = () => {

  const [showRSVPForm, setShowRSVPForm] = useState(false);
  const [showScrollToTop, setShowScrollToTop] = useState(false); // State to track button visibility

  const [introAnimationState, setIntroAnimationState] = useState(0); // Control visibility of h1 and nav
  const highResImageRef = useRef(); // Reference to the high-res image

  const handleRSVPClick = () => {
    setShowRSVPForm(true); // Show the form
  };

  const handleFormClose = () => {
    setShowRSVPForm(false); // Hide the form, show the RSVP button again
  };

  const easeInOutExpo = (t) => {
    if (t === 0 || t === 1) {
      return t;
    }
    if (t < 0.5) {
      return 0.5 * Math.pow(2, (20 * t) - 10);
    } else {
      return -0.5 * Math.pow(2, (-20 * t) + 10) + 1;
    }
  };
  

  const customSmoothScrollTo = (targetSelector, duration = 500, easingFunction = t => t) => {
    const target = document.querySelector(targetSelector);
    if (!target) return;
    const offset = target.id === "intro" ? 0 : 48;
    const targetPosition = target.getBoundingClientRect().top - offset;
    
    const startPosition = window.pageYOffset;
    
    
    const startTime = performance.now();
  
    const animateScroll = currentTime => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      const easeProgress = easingFunction(progress);
      const nextScrollPosition = startPosition + targetPosition * easeProgress;
  
      window.scrollTo(0, nextScrollPosition);
  
      if (elapsedTime < duration) {
        requestAnimationFrame(animateScroll);
      }
    };
  
    requestAnimationFrame(animateScroll);
  };
  

  const smoothScroll = (event) => {
    event.preventDefault(); // Prevent default anchor behavior
    const targetId = event.currentTarget.getAttribute('href'); // Get the href attribute of the clicked anchor
    
    customSmoothScrollTo(targetId, 2000, easeInOutExpo);
  };

  const scrollToTop = () => {
    customSmoothScrollTo("#intro", 2000, easeInOutExpo);
  };

  useEffect(() => {
    const checkScroll = () => {
      const introContentPosition = document.getElementById('intro-content')?.getBoundingClientRect().bottom;
      if (window.scrollY >= introContentPosition) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener('scroll', checkScroll);

    return () => window.removeEventListener('scroll', checkScroll);
  }, []);
  
  useEffect(() => {
    // Sequentially set intro animation states
    const timer1 = setTimeout(() => setIntroAnimationState(1), 300); // Show h1
    
    const timer2 = setTimeout(() => {
      if (highResImageRef.current.complete) {
        // If the image is already loaded (e.g., cached by the browser)
        setIntroAnimationState(2); // Show picture immediately
      } else {
        highResImageRef.current.onload = () => setIntroAnimationState(2); // Show picture after it's loaded
      }
    }, 1300); // Check if image is loaded after showing nav

    const timer3 = setTimeout(() => setIntroAnimationState(3), 1600); // Show nav

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
  }, []);
 
  return (
    <div className="App">
      <div id="intro" className="w-svw h-svh relative">
        <div id="intro-content" className="absolute w-svw h-full flex flex-col items-center justify-center z-10">
          <h1 className={`text-white flex flex-col items-center text-lg text-center mt-auto pt-48 il:pt-4 sm:pt-36 drop-shadow-xl transition-opacity duration-500 ease-in ${introAnimationState >= 1 ? 'opacity-100' : 'opacity-0'}`}>
            <span className="uppercase font-bg font-bold text-sm il:text-sm sm:text-xl tracking-[.15em]">
              In memory of
            </span>
            <span className="text-5xl il:text-5xl sm:text-6xl md:text-8xl xl:text-9xl font-recoleta font-semibold mt-4 mb-6 il:mb-6 il:mt-4 md:mt-10 md:mb-12 transition-all">
              Nicholas E.
              <br />
              Napolski
            </span>
            <span className="uppercase font-bg font-bold text-base il:text-base sm:text-2xl tracking-[.15em]">
              May 3, 1954 &mdash; Feb 11, 2024
            </span>
          </h1>

          <nav
            id="nav"
            className={`font-recoleta mt-auto text-lg px-4 il:text-lg md:text-2xl xl:text-3xl leading-tight font-medium mx-auto text-white z-10 text-center flex justify-center tracking-tight items-end gap-12 il:py-6 py-16 transition-opacity duration-500 ease-in delay-500 ${introAnimationState >= 3 ? 'opacity-100' : 'opacity-0'}`}          
          >
            <a
              href="#celebration"
              onClick={smoothScroll}
              className="flex flex-col items-center gap-4 transition-all"
            >
              <img src="/img/celebration.svg" className="w-16 il:w-16 md:w-auto" alt="Trumpet drawing" />
              <div>Celebration of&nbsp;Life</div>
            </a>
            <a
              href="#about"
              onClick={smoothScroll}
              className="flex flex-col items-center gap-4 transition-all"
            >
              <img src="/img/about.svg" className="w-16 il:w-16 md:w-auto" alt="Mustache and smile drawing" />
              <div>About Nick</div>
            </a>
            <a
              href="#memories"
              onClick={smoothScroll}
              className="flex flex-col items-center gap-4 transition-all"
            >
              <img src="/img/share.svg" className="w-16 il:w-16 md:w-auto" alt="Sun and clouds drawing" />
              <div>Share Memories</div>
            </a>
          </nav>
        </div>

      
        <div id="banner-img" className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ease-in-out ${introAnimationState >= 2 ? 'opacity-100' : 'opacity-0'}`}>
          <picture>                            
            <source ref={highResImageRef}  className="absolute top-0 left-0 w-full h-full object-cover object-top opacity-70 z-0" media="(min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape)" srcSet="/img/ipad-retina-landscape.jpg" />
            <source ref={highResImageRef}  className="absolute top-0 left-0 w-full h-full object-cover object-top opacity-70 z-0" media="(min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait)" srcSet="/img/ipad-retina.jpg" />
            <source ref={highResImageRef}  className="absolute top-0 left-0 w-full h-full object-cover object-top opacity-70 z-0" media="(min-device-width: 375px) and (max-device-width: 812px) and (orientation: portrait)" srcSet="/img/iphone.jpg" />
            <source ref={highResImageRef}  className="absolute top-0 left-0 w-full h-full object-cover object-top opacity-70 z-0" media="(min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape)" srcSet="/img/iphone-landscape.jpg" />
            <source ref={highResImageRef}  className="absolute top-0 left-0 w-full h-full object-cover object-top opacity-70 z-0" media="(max-width: 1080px)" srcSet="img/android.jpg" />
            <img ref={highResImageRef} className="absolute top-0 left-0 w-full h-full object-cover object-top opacity-70 z-0" src="img/desktop@2x.jpg" alt="Photograph of Nicholas E. Napolski at Pokagon State Park, Indiana" />
          </picture>
        </div>

      </div>

      <div id="celebration" className="font-recoleta text-center text-3xl md:text-4xl leading-relaxed my-32 md:mb-0 mx-auto px-2 md:px-16 text-[#DEE3FF]">
        <h2 className="font-semibold text-5xl md:text-6xl tracking-tight text-white mb-8 md:mb-16">
          Celebration of&nbsp;Life
        </h2>
        <p className="font-medium text-2xl md:text-3xl mb-2">
          Niawanda Park Pavilion
        </p>
        <p className="text-sm tracking-wider font-semibold uppercase font-bg mb-8 ">
          600 Niagara St, Tonawanda, NY 14150
        </p>

        <p className="font-medium leading-tight text-white mb-8">
          Friday, March 29, 2024
          <br />
          <span className="">4:00pm &ndash; 10:00pm</span>
        </p>

        <p className="font-medium tracking-normal font-recoleta text-xl md:text-2xl">
          Second Line Procession
          <br />
          <span className="text-lg md:text-xl tracking-normal font-light">
            Time to be determined
            {/* Accompanied by{" "}
            <a
              target="_blank"
              className="hover:underline font-bold"
              rel="noreferrer"
              href="https://www.thebrassmachine.com"
            >
              The Brass Machine
            </a> */}
          </span>
        </p>
       {!showRSVPForm && <button onClick={handleRSVPClick} className="font-bg mt-12 mx-auto block items-center px-12 py-2 mt-16 border border-transparent text-3xl font-semibold rounded-md text-[#152A95] bg-[#FFCD4C] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 top-0 hover:scale-110 shadow-lg hover:shadow-2xl transition-all relative">
          RSVP
        </button> }
        {showRSVPForm && <RSVPForm onCancel={handleFormClose} onSubmit={handleFormClose} />}
      </div>
      <Carousel />   
      <Bio />
   
      <MemoriesAndCondolences />      
      <Footer />
      {showScrollToTop && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-4 right-4 z-50 p-3 w-12 rounded-full bg-yellow-500/65 text-white backdrop-blur-sm"
          aria-label="Scroll to top"
        >
          ↑
        </button>
      )}
    </div>
  );
};

export default HomePage;
