import React, { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  query,
  orderBy,
  where,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import { firestore } from "../firebase"; // Adjust the path as needed

const MemoriesAndCondolences = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [memories, setMemories] = useState([]);

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true, // Use 12-hour format
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Get the user's IP address
      const ipAddress = await fetch("https://api64.ipify.org?format=json")
        .then((response) => response.json())
        .then((data) => data.ip);

      // Check if there is a recent submission from the same IP address
      const recentSubmissionsQuery = query(
        collection(firestore, "memories"),
        where("ipAddress", "==", ipAddress),
        where("timestamp", ">=", Date.now() - 600000) // 10 minutes
      );
      const recentSubmissionsSnapshot = await getDocs(recentSubmissionsQuery);

      if (!recentSubmissionsSnapshot.empty) {
        setError(
          "You have already submitted a message within the past 10 minutes."
        );
        return;
      }

      // Add form data to Firestore
      await addDoc(collection(firestore, "memories"), {
        name,
        email,
        message,
        ipAddress,
        timestamp: serverTimestamp(),
      });

      // Reset form fields after submission
      setName("");
      setEmail("");
      setMessage("");
      setError("");

      // Optionally, display a success message to the user
      alert("Thank you for sharing! Scroll down to see your message posted.");
      fetchMemories();
    } catch (error) {
      console.error("Error adding memory/condolence:", error);
      // Optionally, display an error message to the user
      alert("An error occurred. Please try again later.");
    }
  };

  const fetchMemories = async () => {
    try {
      const memoriesRef = collection(firestore, "memories");
      const q = query(memoriesRef, orderBy("timestamp", "desc"));
      const querySnapshot = await getDocs(q);
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setMemories(newData);
    } catch (error) {
      console.error("Error fetching Memories:", error);
    }
  };

  useEffect(() => {
    fetchMemories();
  }, []);

  return (
    <>
      <div id="memories" className="my-24 md:mt-48 mx-auto px-4 md:px-16">
        <h2 className="font-recoleta font-semibold text-center text-5xl md:text-6xl tracking-tight mb-4 md:mb-8 text-white">
          Memories
        </h2>

        <h3 className="text-xl md:text-3xl leading-normal md:leading-tight font-recoleta font-medium mb-8 pt-4 text-center md:max-w-lg mx-auto text-[#DEE3FF]">
          Share Your Memories&nbsp;&amp;&nbsp;Condolences for&nbsp;Nicholas&nbsp;Napolski
        </h3>
        <div className="mt-8 font-bg bg-[#DEE3FF] text-xl font-medium text-left mx-auto w-full max-w-xl border rounded-3xl px-4 py-10 md:p-16 md:pt-12 text-center text-[#19297A] shadow-2xl">
          <form onSubmit={handleSubmit}>
            {error && <div className="text-red-500 mb-4">{error}</div>}

            <div className="mb-6 md:mb-8 flex flex-col gap-2">
              <label
                htmlFor="message"
                className="font-recoleta font-semibold text-3xl mb-4"
              >
                Your Message
              </label>
              <textarea
                id="message"
                rows="5"
                className="mt-1 block w-full shadow-sm border border-slate-200 rounded-xl p-4"
                value={message}
                placeholder="Add a memory about Nick or share condolences"
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>
            </div>
            <div className="mb-6 md:mb-8 flex text-left flex-col gap-2">
              <label htmlFor="name">From</label>
              <input
                type="text"
                id="name"
                className="mt-1 block w-full shadow-sm border border-slate-200 rounded-xl p-4"
                value={name}
                placeholder="Enter your name"
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="mb-6 md:mb-8 flex text-left flex-col gap-2">
              <label htmlFor="email">Your Email (will not be displayed)</label>
              <input
                type="email"
                id="email"
                placeholder="Enter your email address"
                className="mt-1 block w-full shadow-sm border border-slate-200 rounded-xl p-4"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="flex gap-4 w-full md:mt-12">
              <button
                type="submit"
                className="mx-auto block items-center font-bg mx-auto w-full py-3 border-transparent text-2xl font-semibold rounded-md shadow-sm text-white bg-[#152A95] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
              >
                Share
              </button>
            </div>
          </form>
        </div>
        {memories.length > 0 && (
          <div className="mt-24 md:mt-48">
            <h3 className="text-3xl md:text-5xl text-[#DEE3FF] text-center font-recoleta font-semibold tracking-tight mb-8 md:mb-16">
              Shared Memories of&nbsp;Nick
            </h3>
            <ul className="max-w-lg mx-auto">
              {memories.map((memory) => (
                <li
                  key={memory.id}
                  className="rounded-2xl bg-[#F1F3FF] shadow-xl p-8 md:p-12 mb-8 flex flex-col"
                >
                  <blockquote className="font-recoleta font-normal md:text-xl leading-relaxed text-pretty tracking-[0.015em] mb-4 text-[##041152]">
                    {memory.message}
                  </blockquote>
                  <div className="mx-auto">
                    <div className="font-recoleta font-semibold text-2xl text-center text-[#050e38]">
                      {memory.name}
                    </div>
                    <div className="font-medium text-[#4D5270] tracking-wide text-sm mt-1 text-center">
                      {memory.timestamp
                        .toDate()
                        .toLocaleString("en-US", options)
                        .toUpperCase()}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          )}
      </div>
    </>
  );
};

export default MemoriesAndCondolences;
