import React, { useState } from 'react';
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { firestore } from '../firebase'; // Adjust the path if necessary

const RSVPForm = ({ onCancel, onSubmit }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [response, setResponse] = useState('');
  const [numPeople, setNumPeople] = useState('');
  const [shareStory, setShareStory] = useState(false); // State for checkbox



  const handleSubmit = async (e) => {
    e.preventDefault();    

    try {
      addDoc(collection(firestore, "rsvps"), {
        name,
        email,
        response,
        numPeople: response === 'yes' ? parseInt(numPeople) : 0,
        shareStory, 
        timestamp: serverTimestamp()
      });
  
      // Reset form fields after submission
      setName('');
      setEmail('');
      setResponse('');
      setNumPeople('');
      setShareStory(false); // Resetting checkbox state
  
      // Display a success message to the user, with conditional message for shareStory
      const successMessage = shareStory 
        ? "Thanks for your RSVP! The family will contact you to help schedule your story." 
        : "Thanks for your RSVP!";
      
      alert(successMessage);
      onSubmit();
    } catch (error) {
      console.error('Error adding RSVP:', error);
      // Optionally, display an error message to the user
      alert('An error occurred. Please try again later.');
    }
  };
  
  return (
    <div className="mt-8 font-bg bg-[#DEE3FF] text-xl font-medium text-left mx-auto w-full max-w-md border rounded-3xl px-6 py-10 md:p-16 md:pt-12 text-center text-[#19297A] md:shadow-2xl">      
      <form onSubmit={handleSubmit}>
        <h4 className="font-recoleta font-semibold text-5xl mb-8">R.S.V.P.</h4>
        <div className="mb-8 flex flex-col gap-2">
          <label htmlFor="name">Your Name</label>
          <input
            type="text"
            id="name"
            value={name}
            className="border rounded-lg p-2"
            placeholder='Enter name'
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="mb-8 flex flex-col gap-2">
          <label htmlFor="email">Your Email (optional)</label>
          <input
            type="email"
            id="email"
            value={email}
            placeholder='Enter email'
            className="border rounded-lg p-2"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-8 flex flex-col gap-2">
          <label htmlFor="response">Will You Attend?</label>
          <select
            id="response"
            value={response}
            className="border rounded-lg p-2"            
            onChange={(e) => setResponse(e.target.value)}
            required
          >
            <option value="">Select one...</option>
            <option value="yes">Yes, I will attend</option>
            <option value="no">No, I am unable to attend</option>
          </select>
        </div>
        {response === 'yes' && (
          <>
          <div className="mb-8 flex flex-col gap-2">
            <label htmlFor="numPeople">Number of People Attending</label>
            <input
              type="number"
              id="numPeople"
              value={numPeople}
              min="1"
              pattern="\d*"
              className="border rounded-lg p-2"
              onChange={(e) => setNumPeople(e.target.value.replace(/\D/,''))}
              required
            />
          </div>
          {/* Checkbox and its label for sharing a story */}
          <div className="mb-8 flex align-start text-left">
            <input
              type="checkbox"
              className="inline-block w-4 h-4 m-4"
              id="shareStory"
              checked={shareStory}
              onChange={(e) => setShareStory(e.target.checked)}
            />
            <label className="inline-block text-left w-[80%] leading-tight" htmlFor="shareStory">I would like to share a story during the celebration</label>
          </div>
        </>
        )}
        <div className="flex gap-4 w-full mt-12">
          <button className="font-bg mx-auto w-1/2 items-center py-2 text-2xl font-semibold rounded-md shadow-sm text-[#152A95] border-2 border-[#152A95] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500" onClick={onCancel}>Cancel</button>
          <button className="font-bg mx-auto w-1/2 order border-transparent text-2xl font-semibold rounded-md shadow-sm text-white bg-[#152A95] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500" type="submit">Submit</button>
        </div>
        
      </form>
      
    </div>

   
  );
};

export default RSVPForm;
